<template>
  <div>
    <v-card flat elevation="0" class="">
      <!-- <v-card-title>Offer</v-card-title>
      <v-divider></v-divider> -->
      <app-data-table ref="refAppDataTable" :uri="datatable.uri" :headers="datatable.headers" :filter-data="datatable.filter">
        <template v-slot:title="{ item }">
          <router-link :to="'/purchasing/eprocannouncement/detail?id=' + item.id">{{ item.title }}</router-link>
        </template>

        <template v-slot:published="{ item }">
          <div>
            <v-icon v-if="item.published" color="primary" @click="publishData(item)">mdi-eye</v-icon>
            <v-icon v-else @click="publishData(item)">mdi-eye-off</v-icon>
          </div>
        </template>
        <template v-slot:options="{ item }">
          <div>
            <v-btn :to="'/purchasing/eprocannouncement/edit?id=' + item.id" icon text color="primary" small><v-icon>mdi-pencil</v-icon></v-btn>
            <v-btn @click="deleteData(item)" icon text color="primary" small><v-icon>mdi-delete</v-icon></v-btn>
          </div>
        </template>
      </app-data-table>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      loading: null,
      loading_delete: false,
      loading_publish: false,

      datatable: {
        uri: "eprocannouncement/data",
        headers: [
          { text: "Title", value: "title", class: "text-no-wrap", cellClass: "text-no-wrap" },
          { text: "Created Date", value: "created_date", class: "text-no-wrap", cellClass: "text-no-wrap" },
          { text: "Created By", value: "created_by_username", class: "text-no-wrap", cellClass: "text-no-wrap" },
          { text: "Published", value: "published", class: "text-no-wrap text-center", cellClass: "text-center text-no-wrap" },
          { text: "Views", value: "views", class: "text-no-wrap", cellClass: "text-end text-no-wrap" },
          { text: "Opsi", value: "options", class: "text-no-wrap text-center", cellClass: "text-center text-no-wrap" },
        ],
        filter: {
          status: this.status
        }
      },
    };
  },
  watch: {
    status(val) {
      this.datatable.filter.status = val;
    }
  },
  methods: {
    refreshData() {
      this.$refs.refAppDataTable.refresh();
    },
    deleteData(item) {
      let post = async () => {
        this.loading_delete = true;
        this.showLoadingOverlay(true);
        const formData = new FormData();
        formData.append("id", item.id);
        await this.$axios
          .post("eprocannouncement/delete", formData)
          .then((res) => {
            this.loading_delete = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);
            this.refreshData();
          })
          .catch((error) => {
            this.loading_delete = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      }
      let msg = "Delete?";
      this.showConfirm("Confirm", msg, post);
    },
    publishData(item) {
      let post = async () => {
        this.loading_publish = true;
        this.showLoadingOverlay(true);
        const formData = new FormData();
        formData.append("id", item.id);
        await this.$axios
          .post("eprocannouncement/publish", formData)
          .then((res) => {
            this.loading_publish = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);
            this.refreshData();
          })
          .catch((error) => {
            this.loading_publish = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      }
      let msg = "Publish?";
      if (item.published) {
        msg = "Unpublish?";
      }
      this.showConfirm("Confirm", msg, post);
    },
  },
};
</script>
